import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { BsClock, BsPeople, BsFillBookmarksFill } from 'react-icons/bs'
import slugify from 'slugify'

const ArticleTemplate = ({ data }) => {
  const {
    title,
    seoTitle,
    seoDescription,
    shortDescription,
    content,
    image,
    createdBy,
    createdWhen,
    tags,
  } = data.contentfulArticle
  const pathToImage = getImage(image)
  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} article={true} />
      <main className='page'>
        <div className='article-page'>
          {/*hero*/}
          <section className='article-hero'>
            <GatsbyImage
              image={pathToImage}
              alt={title}
              className='article-img'
            />
            <article className='article-info'>
              <h1>{title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: shortDescription.childMarkdownRemark.html,
                }}
              />
            </article>
          </section>
          {/*rest of content*/}
          <section className='article-content'>
            <article>
              <h4>Artikel</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.childMarkdownRemark.html,
                }}
              />
            </article>
            <article>
              <div className='article-icons'>
                <article>
                  <BsClock />
                  <h5>Created when</h5>
                  <p>{createdWhen}</p>
                </article>
                <article>
                  <BsPeople />
                  <h5>Created By</h5>
                  <p>{createdBy}</p>
                </article>
                <article>
                  <BsFillBookmarksFill />
                  <h5>Tags</h5>
                  <p className='article-tags'>
                    {tags.map((tag, index) => {
                      const slug = slugify(tag, { lower: true })
                      return (
                        <Link to={`/tags/${slug}`} key={index}>
                          {tag}
                        </Link>
                      )
                    })}
                  </p>
                </article>
              </div>
            </article>
          </section>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleArticle($title: String) {
    contentfulArticle(title: { eq: $title }) {
      title
      seoTitle
      seoDescription
      createdBy
      createdWhen
      tags
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData
      }
    }
  }
`
export default ArticleTemplate
